@import "../../../App.scss";

.followup_container {
  .question_text {
    margin-bottom: 32px;
  }
  .tbl_wrapper {
    margin-bottom: 40px;
  }

  .followup_heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 24px;
    @include font_gt_walsheim_medium;
    text-transform: uppercase;
  }  
  .response_text {
    display: flex;
    align-items: flex-start; 
    justify-content: flex-start; 
  }
  
  .respone_logo {
    display: flex;
    flex-shrink: 0; 
    align-items: flex-start; 
    margin-right: 16px; 
  }
  
  .ai_logo {
    width: 40px;
    height: auto;
    padding-top: 15px;
  }
  
  .tbl_wrapper {
    flex-grow: 1; 
  }
  
}