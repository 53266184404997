@import "../../../App.scss";

.report_wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  // padding: 48px 0 48px;

  .report_header_wrapper {
    display: flex;
    flex-direction: column;
    @include breakpoint (md){
      flex-direction: row;
      justify-content: space-between;
    }
    .heading_wrapper {
      margin-bottom: 32px;

      .report_heading {
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 15px;
        @include font_gt_walsheim_bold;
        @include breakpoint(md){
          font-size: 24px;
          line-height: 28px;
        }
      }

      .report_subheading {
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim;
        @include breakpoint(md){
          font-size: 18px;
          line-height: 21px;
        }
      }
    }

    .nav_btn_wrapper {
      display: flex;
      @include font_gt_walsheim;
      font-size: 16px;
      line-height: 24px;
      max-width: 352px;
      margin-bottom: 24px;
      @include breakpoint(md){
        width: 100%;
        margin-bottom: 0;
      }
      Button {
        padding: 0 32px;
        min-width: auto;
        height: 36px;

        &:first-child {
          margin-right: 24px;
          min-width: 135px;
        }
      }
    }
  }
  .card_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    .card_loader {
      padding: 16px;
      border-radius: 12px;
      flex: 1 1 calc(33.33% - 16px);
      height: 190px;
    }
  }
  .card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
    cursor: pointer;

    .card {
      padding: 16px;
      border-radius: 12px;
      flex: 1 1 calc(33.33% - 16px);
      height: 190px;
      overflow-y: auto;
      .card_heading {
        font-size: 16px;
        margin-bottom: 8px;
        line-height: 21px;
        @include font_gt_walsheim_bold;
        @include breakpoint(md){
          font-size: 18px;
          line-height: 21px;
        }
      }

      .card_content {
        font-size: 14px;
        @include font_gt_walsheim;
        line-height: 18px;
      }
    }
  }

  .report_builder_section {
    width: 438px;
    height: 56px;
  }
}


