@import "../../../App.scss";

.loader_anime {
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  .loading_icon {
    margin-right: 16px;
  }

  .dots_anime {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .animatedDots {
    display: inline-block;

    span {
      display: inline-block;
      animation: dots 0.6s infinite;
      /* Faster animation duration */
      opacity: 0;
      margin-right: 2px;
    }

    span:nth-child(1) {
      animation-delay: 0s;
      /* Immediate start */
    }

    span:nth-child(2) {
      animation-delay: 0.15s;
      /* Reduced delay */
    }

    span:nth-child(3) {
      animation-delay: 0.3s;
      /* Reduced delay */
    }
  }

  @keyframes dots {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}