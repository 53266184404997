@import "../../../App.scss";
.button {
  height: 48px;
  padding: 0 32px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  font-size: 16px;
  line-height: 18px;
  transition: background-color 0.3s ease;
  @include font_gt_walsheim_bold;
  @include flex_center;
  // width: 100%;
  min-width: 135px;
  .btn_inner {
    display: flex;
  }
  .btn_icon {
    margin-right: 8px;
  }
  &:first-child {
    margin-right: 12px;
  }
  .label {
    white-space: nowrap;
  }
  &.disabled {
    cursor: not-allowed;
  }
  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
  }
}
