@import "../../../styles/all.scss";

.dropdown_container {
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  width: 159px;
  position: relative;
  // margin-left: 20px;
  border-radius: 12px;
  width: 159px;
  .dropdown_heading {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    .dropdown_button {
      padding: 10px;
      cursor: pointer;
      background-color: unset;
      border: none;
      @include font_gt_walsheim_bold;
      font-size: 16px;
      line-height: 24px;
    }

    .arrow_size {
      height: 10px;
      width: 16px;
      background-size: contain;
    }
  }

  .dropdown_menu {
    position: absolute;
    right: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    border-radius: 12px;
    @include font_gt_walsheim_medium;
    font-size: 16px;
    line-height: 24px;

    .dropdown_item {
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 10px 28px;

      cursor: pointer;

      &:first-child {
        border-radius: 12px 12px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
}