@import "../../../App.scss";

.profile_card {
  position: relative;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  &:last-child{
    margin-bottom: 0;
  }
  .content_container {
    width: 708px;
  }

  .abo_name {
    font-size: 18px;
    line-height: 21px;
    @include font_gt_walsheim_bold;
    margin-bottom: 4px;
  }

  .abo_details {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    .details_wrapper{
      margin-right: 8px;
    }
  }
  .violation_desc {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    .kpi_details {
      display: flex;
      flex: 1 1 50%;
      max-width: 50%;
      &:nth-child(even){
        padding-left: 12px;
      }
      @include breakpoint(md){
        flex: 1 1 33.3%;
        max-width: 33.3%;
        &:nth-child(even){
          padding-left: 0;
        }
      }
      .kpi_wrapper {
        display: flex;
        white-space: nowrap;
        .value_wrapper {
          display: flex;
          align-items: center;
          padding-left: 4px;
          .value {
            @include font_gt_walsheim_bold;
          }
          .flag_wrapper {
            padding-left: 4px;
            background: none;
          }
        }

      }
    }
  }
  .flag_wrapper {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    .flag_type {
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
    }

    .flag {
      margin-right: 4px;
    }
  }

  .arrow_icon {
    opacity: 1;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 16px;
    img {
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

}
