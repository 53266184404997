@import "../../../../styles/all.scss";

.section_title_wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  padding: 24px 0 24px;

  @include breakpoint(md) {
    padding: 24px 0 24px;
  }

  .dropdown_icon {
    transition: transform 0.3s ease;
    height: 24px;
    width: 24px;
    margin-left: 15px;
    cursor: pointer;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.section_label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  .main_label {
    @include font_gt_walsheim_bold;
    font-size: 16px;
    line-height: 20px;
  }

  .sub_label {
    display: flex;
    @include font_gt_walsheim_medium;
    font-size: 14px;
    line-height: 16px;
  }
}

.milstone_wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
    padding-bottom: 24px;
    gap: 24px;
  }
}

.milstone_row {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  gap: 10px;

  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

.milstone_item {
  min-width: 278px;
}

.item_key {
  margin-right: 16px;
  @include font_gt_walsheim_bold;
}

.qualification_main_wrapper {
  padding: 12px 0 24px;
}

.award_year {
  margin-right: 16px;
}

.award_tbl_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
  }

  .award_table {
    width: 100%;
    border-radius: 12px;
    border-collapse: collapse;
    overflow: hidden;

    @include breakpoint(md) {
      width: 50%;
    }
  }

  thead {
    .tbl_head {
      th {
        height: 52px;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        padding: 0 16px;
        width: 33.33%;
        vertical-align: middle;
        @include font_gt_walsheim_bold;

        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(3) {
          text-align: left;
          border-right: none;
        }
      }
    }
  }

  tbody {
    .tbl_row {
      min-height: 40px;

      td {
        padding: 8px 16px;
        font-size: 16px;
        line-height: 24px;
        width: 50%;
        vertical-align: middle;
        min-height: 40px;

        &:first-child {
          text-align: left;
          @include font_gt_walsheim_medium;
        }

        &:nth-child(2) {
          text-align: left;
          @include font_gt_walsheim_medium;

          &.medium_font {
            @include font_gt_walsheim_medium;
          }
        }

        &:nth-child(3) {
          text-align: left;
          border-right: none;
          @include font_gt_walsheim_medium;
        }
      }

      .award_name_container {
        display: inline-flex;
        align-items: center;
        gap: 10px;
      }

      .tracking_tag {
        display: inline-block;
        padding: 2px 4px;
        align-items: center;
        border-radius: 4px;
        width: 100%;
      }
    }
  }

  .three_col_tbl {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoint(md) {
      width: 50%;
    }

    thead,
    tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    tr {
      display: flex;
      width: 100%;
    }

    th,
    td {
      padding: 8px;
      text-align: left;
      display: flex;
      align-items: center;
    }

    th {}

    td:nth-child(1),
    th:nth-child(1) {
      flex: 0 0 20%;
    }

    td:nth-child(2),
    th:nth-child(2) {
      flex: 0 0 40%;
    }

    td:nth-child(3),
    th:nth-child(3) {
      flex: 0 0 40%;
    }
  }

  .three_col_tbl tbody tr td:nth-child(2),
  .three_col_tbl tbody tr td:nth-child(3) {
    text-align: left;
    @include font_gt_walsheim;
  }
}