@import "../../../App.scss";

.card_wrapper {
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;

  .header_wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    @include breakpoint(md){
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .header {
    height: 36px;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 24px;
    @include font_gt_walsheim_bold
  }

  .create_report_button {
    border-radius: 100px;
    cursor: pointer;
    height: 36px;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    .create_text {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_bold;
      .plus_icon {
        margin-right: 8px;
      }
    }
  }

  .report_card {
    max-width: 800px;
    margin: 0 auto;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;


    .report_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 16px;

      .report_title_wrapper {
        font-size: 18px;
        margin-bottom: 8px;
        line-height: 21px;
        @include font_gt_walsheim_bold;
      }


      .report_details {
        font-size: 14px;
        line-height: 18px;
      }

      .first_supporting_text {
        @include font_gt_walsheim;
        padding-right: 12px;
      }

      .second_supporting_text {
        @include font_gt_walsheim;
        border-left: 1px solid;
        padding-left: 12px;
      }

      .report_actions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .preview_button {
          margin-right: 24px;
          font-size: 14px;
          line-height: 18px;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          text-decoration: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: 5px;
          @include font_gt_walsheim_bold
        }

        .download_button {
          border-radius: 25px;
          cursor: pointer;
        }
      }
    }
  }

  div[class*="ai_insight_wrapper InsightAI_ai_insight_wrapper__M547X ReportCard_report_insight__uYTwI"]{
    padding: 0;
  }
  div[class*="markdown_styled InsightAI_markdown_styled__o0+fA InsightAI_ai_content_wrapper__QbxsR"]{
    padding: 0;
    margin-top: 0;
  }
}