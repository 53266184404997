@import "../../../styles/all.scss";

.footer_container {
  z-index: 7;
  padding: 20px 24px;
  margin-top: auto;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include breakpoint(md) {
    flex-direction: row;
    padding: 24px;
    align-items: center;
    justify-content: space-between;
  }
  .footer_content {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    @include breakpoint(md) {
      margin-bottom: 0;
    }
    .content {
      margin-right: 20px;
    }
    .links {
      cursor: pointer;
      a {
        text-decoration: none;
        @include font_gt_walsheim;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .navlist_left {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    @include breakpoint(md){
      align-items: center;
      justify-content: space-between;
    }
    @include breakpoint(lg){
      gap: 0;
    }
    .nav_item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 32px;
      cursor: pointer;
      @include breakpoint(md){
        margin-right: 16px;
      }
      @include breakpoint(lg) {
        margin-right: 32px;
      }

      .ai_flower_logo {
        margin-right: 2px;
      }

      .label {
        margin-right: 4px;
        font-size: 14px;
        line-height: 18px;
        white-space: nowrap;
        @include font_gt_walsheim;

        @include breakpoint(lg) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .icon_wrapper {
        width: 16px;
        height: 16px;
      }

    }
  }

}
