@import "../../../App.scss";

.agc_details_tile {
  .agc_tile_wrapper {
    padding: 32px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 12px;
    margin-bottom: 24px;
    position: relative;
    @include breakpoint(md){
      flex-direction: row;
    }
    .close_tile {
      position: absolute;
      top: 13px;
      right: 16px;
      .close_btn{
        cursor: pointer;
      }
    }
    .left_section {
      width: 100%;
      margin-bottom: 16px;
      @include breakpoint(md){
        width: 50%;
        padding-right: 48px;
        margin-bottom: 0;
      }
      .tile_header {
        margin-bottom: 24px;
        .title {
          font-size: 21px;
          line-height: 24px;
          @include font_gt_walsheim_bold;
          margin-bottom: 8px;
        }

        .desc {
          font-size: 16px;
          line-height: 24px;
          @include font_gt_walsheim;
        }
      }

      .benchmark_section {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
        .benchmark_value {
          min-width: 25%;
          max-width: 45%;
          height: 100px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          padding: 0 8px;
          .yoy_value {
            font-size: 24px;
            line-height: 28px;
            @include font_gt_walsheim_bold;
            margin-bottom: 8px;
          }

          .yoy {
            font-size: 16px;
            text-align: right;
            @include font_gt_walsheim;
            line-height: 18px;
            text-transform: uppercase;
            .change_in_value {
              font-size: 21px;
              line-height: 24px;
              @include font_gt_walsheim_bold;
              margin-right: 4px;
            }
          }
        }

        .benchmark_bar {
          width: 75%;
        }
      }

      .insight_section {
        margin-top: 20px;
        .ai_insight_wrapper {
          border-radius: 12px;
          height: 48px;
        }
      }
    }
    .right_section {
      width: 100%;
      @include breakpoint(md){
        width: 50%;
      }
      .activation_wrapper {
        display: flex;
        width: 257px;
        height: 36px;
        border-radius: 20px;
        margin-bottom: 24px;
        margin-left: auto;
        margin-top: 8px;
        .rate_period {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          cursor: pointer;
          font-size: 14px;
          line-height: 18px;
          @include font_gt_walsheim_medium;
        }
      }
      .table_section {
        padding-top: 8px;
      }
    }
  }
}