@import "../../../../App.scss";

.business_philosophy_container {
  width: 100%;
  position: relative;

  .business_qaulitative_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .header_wrapper {
      width: 100%;

      .heading_area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .heading {
          @include font_gt_walsheim_bold;
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
          width: 70%;
        }

        .right_section {
          display: flex;
          justify-content: flex-end;

          .edit_section {
            display: flex;
            @include font_gt_walsheim_bold;
            font-size: small;
            padding-left: 10px;
            text-decoration: underline;
            text-decoration-thickness: 2px;
            text-underline-offset: 3px;
            cursor: pointer;

            .edit_icon_light {
              background-image: url("../../../../assets/fonts/edit-icon-light.svg");
              height: 15px;
              width: 14px;
              margin-right: 7px;
            }

            .edit_icon_dark {
              background-image: url("../../../../assets/fonts/edit-icon-dark.svg");
              height: 15px;
              width: 14px;
              margin-right: 7px;
            }
          }
        }
      }

      .sub_heading {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }

      .last_modified {
        padding-top: 10px;
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .btn_wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-top: 8px;
    margin-bottom: 40px;

    Button {
      width: 50%;
    }

    @include breakpoint(md) {
      width: 290px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .details_wrapper {
    display: flex;
    flex-direction: column;
    @include breakpoint(md){
      flex-direction: row;
    }
    .flex_wrapper {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      &:first-child{
        @include breakpoint(md){
          padding-right: 48px;
        } 
      }
      &:last-child{
        @include breakpoint(md){
          padding-left: 48px;
        }
      }
      @include breakpoint(md) { 
        flex: 1 1 50%;
        max-width: 50%; 
      }
      .item_wrapper {
        flex: 1 1 100%;
        max-width: 100%;
        margin-bottom: 24px;
  

  
        .item_name {
          @include font_gt_walsheim_bold;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
  
        .item_value {
          line-height: normal;
          width: 100%;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
  
        .succession_question_text {
          padding-bottom: 8px;
          @include font_gt_walsheim_bold;
        }
  
        .options {
          display: flex;
          gap: 50px;
        }
  
        .have_succession {
          padding: 12px;
          border-radius: 12px;
        }
      }
    }
  }
}