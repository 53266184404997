@import "../../App.scss";
@import "../../styles/all.scss";

.dropdown_container {
  position: relative;
  border: 0;
  width: auto;
  .dropdown_heading {
    display: flex;
    align-items: center;
    justify-content: center;
	  cursor: pointer;
    .dropdown_button {
      margin-right: 4px;
      cursor: pointer;
      background-color: unset;
      border: none;
      @include font_gt_walsheim;
      font-size: 16px;
      line-height: 24px;
      padding: 0;
    }

    .dropdown_arrow {
      height: 10px;
      width: 16px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .dropdown_menu {
    position: absolute;
    width: 90px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 5;
    border-radius: 12px;
    @include font_gt_walsheim_medium;
    font-size: 16px;
    line-height: 24px;

    .dropdown_item {
      padding: 10px;
      cursor: pointer;
      &:first-child {
        border-radius: 12px 12px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
}
