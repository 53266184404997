@import "../../../../styles/all.scss";
.contact_info_wrapper {
  padding: 24px;
  border-radius: 12px;
}
.card_holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 48px;
}
.section_title_wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  .dropdown_icon {
    transition: transform 0.3s ease;
    height: 18px;
    width: 18px;
    margin-left: 15px;
    cursor: pointer;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}
.section_label {
  @include font_gt_walsheim_medium;
  font-size: 16px;
  line-height: 18px;
}
.viewmore_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 0;
  .viewmore_label {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    font-feature-settings:
      "clig" off,
      "liga" off;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}
