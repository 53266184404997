@import "../../../App.scss";

.profile_container {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  min-height: 140px;
  height: auto;
  .side_gredient {
    height: 100%;
    width: 16px;
    border-radius: 12px 0px 0px 12px;
    flex-shrink: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .error_text {
    text-align: center;
  }
  .profile_wrapper {
    min-height: 140px;
    // border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    .profile_inner_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      min-height: 140px;
      width: 100%;
      padding: 16px 24px;
      padding-left: 40px;
      .profile_main_info_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        .profile_image_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 21px 17px 21px 17px;
          height: 54px;
          width: 54px;
          border-radius: 50%;
          margin-right: 16px;
          @include breakpoint(lg) {
            height: 68px;
            width: 68px;
          }
          .profile_image {
            font-size: 18px;
            @include font_gt_walsheim_bold;
            line-height: 21px;
          }
        }
        .abo_info_name_number {
          width: calc(100% - 84px);
          margin-right: 24px;
          .abo_name {
            font-size: 24px;
            line-height: 28px;
            @include font_gt_walsheim_bold;
            @include ellipsis_two_liner;
            margin-bottom: 8px;
            @include breakpoint(lg) {
              font-size: 32px;
              line-height: 36px;
              margin-bottom: 4px;
              min-height: 40px;
            }
          }
          .abo_number_status {
            font-size: 14px;
            line-height: 18px;
            display: flex;
            @include font_gt_walsheim;
            @include breakpoint(lg) {
              font-size: 16px;
              line-height: 24px;
            }
            .country {
              margin-left: 8px;
            }
            .pipeIcon {
              display: flex;
              margin-left: 8px;
              align-items: center;
            }
            .abo_status {
              margin-left: 8px;
              @include font_gt_walsheim;
            }
          }
          .account_name_wrapper {
            display: flex;
            align-items: center;
            .account_name {
              font-size: 14px;
              line-height: 18px;
              letter-spacing: 0.28px;
              text-transform: uppercase;
              margin-right: 4px;
              @include font_gt_walsheim_medium;
            }
            .account_name_value {
              font-size: 14px;
              line-height: 18px;
              @include font_gt_walsheim_bold;
            }
          }
        }

        @include breakpoint(lg) {
          width: calc(45% - 24px);
          height: 100%;
          margin-bottom: 0;
        }
      }

      .abo_info_account_pin {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;

        .field_title_wrapper {
          display: flex;
          margin-bottom: 2px;
          @include font_gt_walsheim_medium;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.28px;
          .currentPin {
            overflow-wrap: break-word;
            white-space: normal;
            &:last-child {
              padding-right: 0;
            }
          }
          .upline_name {
            text-transform: uppercase;
          }
          @include breakpoint(lg) {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .field_data_wrapper {
          display: flex;
          @include font_gt_walsheim_bold;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.28px;
          .upline_name {
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;
          }
          .loaName {
            text-decoration: underline;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;
          }

          .trackingPin_wrapper {
            display: flex;
            align-items: flex-start;
            .awardIcon {
              display: flex;
              align-items: center;
              padding-top: 2px;
              padding-right: 4px;
            }
            .trackingPin {
              margin-left: 4px;
            }
          }
          .currentPin_wrapper {
            display: flex;
            align-items: flex-start;
            .awardIcon {
              display: flex;
              align-items: center;
              padding-top: px;
              padding-right: 4px;
            }
            .currentPin {
              margin-left: 4px;
              overflow-wrap: break-word;
              white-space: normal;
            }
          }
        }
        .data_item {
          width: 25%;
          margin-right: 40px;
          letter-spacing: 0.28px;
          @include ellipsis_two_liner;
          word-break: break-word;
          &:last-child {
            margin-right: 0;
          }
          &.data_value {
            min-height: 40px;
            .values {
              @include ellipsis_two_liner;
              word-break: break-word;
              overflow-wrap: break-word; 
              white-space: normal;
            }
          }
        }
        @include breakpoint(lg) {
          width: 55%;
          padding-top: 16px;
        }
      }
      @include breakpoint(lg) {
        width: calc(100% - 16px);
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 24px 0 24px 48px;
      }
    }
  }
}
.text_center {
  text-align: center;
}
