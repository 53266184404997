@import "../../../App.scss";

.ap_view_profile_container {
  .profile_content {
    padding: 24px 32px;
    flex: 1;

    .flex_wrapper {
      display: flex;
      align-items: flex-start;

      .content_wrapper {
        height: 100%;
        border-radius: 12px;
        width: 100%;

        @include breakpoint(lg) {
         
        }

        .inner_wrapper {
          .ap_info_title_container {
            width: 100%;
            .ap_dropdown_wrapper {
              position: relative;
              width: 343px;
              right: 0;
              z-index: 2;
            }
          }

          .bottom_line {
            margin-bottom: 24px;
          }
        }
      }
    }

    @include breakpoint(lg) {
      padding: 24px 48px;
    }
  }
}

.active_section {
  display: block;
}

.hidden_section {
  display: none;
}