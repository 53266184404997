@import "../../../../styles/all.scss";

.ap_info_details_container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  .inner_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .section_container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    
      .section_wrapper {
        width: 100%;
        @include breakpoint(md){
          width: 50%;
        }
      }
      @include breakpoint(md){
       flex-direction: row;
      }
    }
    .section_wrapper {
      margin-bottom: 20px;
    }
    .section_label {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}
