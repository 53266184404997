@import "../../App.scss";

.profile_container {

  .profile_content {
    padding: 16px 32px;
  }

  .below_silver_selected_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 351px;
    margin: auto;
    padding: 48px;
  }

  .below_silver_image {
    width: 387px;
    height: 207px;
    object-fit: cover;
    margin-bottom: 24px;
  }

  .below_silver_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .flex_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .tab_wrapper {
      display: flex;
      align-items: center;
      gap: 24px; 
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        display: none
      }
      scrollbar-width: none;
      -ms-overflow-style: none;

      .tab_name {
        @include font_gt_walsheim_medium;
        font-size: 16px;
        font-style: normal;
        line-height: 32px;
        cursor: pointer;

        &.selected {
          @include font_gt_walsheim_bold;
          font-weight: 700;
        }
      }
    }

    .content_wrapper {
      height: 100%;
      border-radius: 12px;
      width: 100%;
      // width: calc(100% - 181px);
      // @include breakpoint(lg) {
      //   width: calc(100% - 224px);
      // }

      .inner_wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  @include breakpoint(lg) {
    .profile_content {
      padding: 16px 48px;
    }
  }
}

.active_section {
  display: block;

  @include breakpoint(md){
    margin-bottom: 20px;
  }
}

.hidden_section {
  display: none;
}