@import "../App.scss";

.layout_container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  &.login_page {
    .main_container {
      padding-left: 0px;
    }
  }
  .main_layout_content {
    padding-top: 72px;
    // margin-top: auto;
  }

  .main_container {
    padding-left: 121px;
    min-height: 100vh;
    width: 100%;
    transition: padding-left 0.3s ease-in-out;

    &.expanded_sidenav {
      padding-left: 266px;

      @include breakpoint(md) {
        padding-left: 296px;
      }
    }

    .main_wrapper {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 16px);
      border-radius: 12px;
      margin: 8px 8px 8px 0px;

      &.login_page {
        margin: 0px;
        border-radius: 0px;
        min-height: 100vh;
      }
    }
  }

}