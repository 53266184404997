@import "../../../styles/all.scss";

.personal_info_wrapper {
  width: 100%;
  height: 100%;
  border-radius: 12px;

  .inner_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .section_wrapper {
      margin-bottom: 20px;
      position: relative;
      &:first-child {
        margin-bottom: 40px;
      }
    }

    .verticalSection {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding-top: 50px;

      @include breakpoint(md) {
        flex-direction: row;
      }

      .vsection_wrapper {
        width: 100%;
        margin-bottom: 50px;

        @include breakpoint(md) {
          width: 50%;
          margin-bottom: 0;
        }
      }

      .separator_line {
        width: 1px;
        margin-left: 40px;
        margin-right: 40px;
        display: none;

        @include breakpoint(md) {
          display: block;
        }
      }
    }

    .section_label {
      font-feature-settings:
        "clig" off,
        "liga" off;
      @include font_gt_walsheim;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }
}