@import "../../../../App.scss";

.insertLinkModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: 268px;
  top: 35%;
  left: 40%;
  padding: 20px;
  border-radius: 12px;
  z-index: 1000;
  gap: 10px;

  .HeaderText {
    @include font_gt_walsheim;
  }

  .inputLinkOuter {
    position: relative;
    display: flex;
    height: 38px;
    border-radius: 12px;

    .linkValidationImg {
      width: 18px;
      height: 22px;
      margin: 6px;
    }
  }

  .customInputText {
    flex: 1;
    border: none;
    border-radius: 12px;
    outline: none;
    padding: 3px 12px;
    @include font_gt_walsheim;
  }

  .inputText {
    display: flex;
    width: 100%;
    border: none;
    border-radius: 12px;
    outline: none;
    padding: 3px 12px;
    align-items: center;
    align-self: stretch;
    @include font_gt_walsheim;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    margin-top: 10px;

    .buttonStyles {
      display: flex;
      height: 36px;
      padding: 6px 30px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      @include font_gt_walsheim_bold;
    }

  }
}