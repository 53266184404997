.theme_switch_container {
  display: flex;
  width: 144px;
  height: 36px;
  align-items: flex-start;
  border-radius: 24px;
  overflow: hidden;
  
  &.isSidenavToggle {

  }
  &.isNotExpanded{
    &.isSidenavToggle {
      width: 72px;
     }
  }
}

.toggle_switch_btn {
  display: flex;
  width: 50%;
  height: 100%;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  cursor: pointer;
  transition:
    background-color 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) ease-in-out,
    border-radius 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease-in-out,
    transform 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease-in-out;
  border-radius: 0;
}

.toggle_switch_left {
  background-color: #5969d6;
  border-radius: 24px;
  z-index: 1;
}

.toggle_switch_right {
  background-color: #99b2f5;
  border-radius: 24px;
}

.toggle_switch_btn.active {
  background-color: #282828;
  border-radius: 24px;
}
