@import "./styles/all.scss";

.theme_provider {
  height: 100vh;
  &.light {
    @import "./styles/lightTheme";
    background-color: $theme_bg_color;
    color: $text_color;
    // add the main class of componenents here which needs to be change dynamically(Themes/colors only)
  }
  &.dark {
    @import "./styles/darkTheme";
    background-color: $theme_bg_color;
    color: $text_color;
  }
}
.apexcharts-tooltip {
  border: none !important;
  box-shadow: none !important;
}

.markdown_styled {
  @include font_gt_walsheim;
  font-size: 16px;
  line-height: 24px;
  a {
    text-decoration: none;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    line-height: 20px;
  }

  .tbl_wrapper {
    overflow-x: auto;
    margin-top: 20px;
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar {
    height: 2px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th,
    td {
      border: 1px solid ;
      text-align: left;
      padding: 8px;
      min-width: 150px;
      white-space: nowrap;
      text-align: left !important;
    }

    th {
      @include font_gt_walsheim_bold;
    }
  }

  pre {
    padding: 5px;
    border-radius: 5px;
    overflow-x: auto;
  }
}

.markdown_styled ol {
  list-style-type: decimal;
  margin-left: 20px;
}

.markdown_styled ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 12px;
}
.markdown_styled strong {
  @include font_gt_walsheim_bold;
}
.markdown_styled p {
  margin-bottom: 1em;
}

.markdown_styled th,
.markdown_styled td {
  border: 1px solid;
  padding: 8px;
  text-align: left;
}
