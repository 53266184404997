@import "../../../../../App.scss";

.summary_section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  margin-bottom: 24px;
  @include breakpoint(md){
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  &.expanded_section{
    grid-template-columns: 1fr;
    @include breakpoint(md){
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.tile_container {
  padding: 12px;
  border-radius: 12px;
  height: 203px; // Fixed height
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}