@import "../../../App.scss";

.login_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    padding: 16px 48px;
    z-index: 11;
    margin: 0;

    @include breakpoint(md) {
      padding: 16px 32px;
    }
  
    .amway_logo {
      height: auto;
      width: 117.683px;
      display: flex;
      justify-content: flex-start;
    }
}