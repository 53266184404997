@import "../../../App.scss";

.main_sidebar {
  position: fixed;
  top: 8px;
  left: 8px;
  bottom: 8px;
  width: 105px;
  border-radius: 12px;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  z-index: 12;
  overflow: hidden;

  .bg_gradient {
    position: absolute;
    top: -8px;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: inherit;
    z-index: -1;
    border-radius: 12px;
  }

  &.expanded {
    width: 250px;

    .top_nav_section {
      justify-content: flex-start;
      padding: 0 8px;

      .amway_logo {
        margin-right: 8px;
      }
      .logo_title {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .nav {
      justify-content: flex-start;

      .nav_list {
        padding: 0;
        width: 100%;

        .nav_item {
          justify-content: flex-start;
          width: auto;
          min-width: 48px;

          .link_icon {
            margin-right: 12px;
          }
        }

        .side_nav_item {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
    .navlist_bottom {
      align-items: flex-start;
      padding: 0 16px;
    }
    @include breakpoint(md) {
      width: 280px;
    }
  }

  .top_nav_section {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    a {
      display: flex;
      align-items: center;
    }

    .logo_title {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_bold;
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .toggle_button {
      cursor: pointer;
      position: fixed;
      top: 42px;
      left: 100px;
      z-index: 35;
      transition: all 0.3s ease-in-out;

      &.toggle_position {
        left: 240px;
      }

      .sidenav_btn {
        width: 24px;
        height: 24px;
        transition: all 0.3s ease-in-out;

        &.expanded_btn {
          transform: rotate(180deg);
        }
      }
    }
  }

  .nav {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 99px;
        width: 48px;
        height: 48px;
        margin-bottom: 4px;
        padding: 0 16px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .side_nav_item {
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }
  .navlist_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    @include breakpoint(md) {
      display: none;
    }
    .logout_btn {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      margin-bottom: 32px;
      .logout_txt {
        display: inline-flex;
        margin-right: 8px;
        font-size: 16px;
        line-height: 24px;
      }

      .logout_img {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
      }
    }

    .toggle_switch_wrapper {
    }
  }
}