@import "../../../../App.scss";

.ap_view_profile_container {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  min-height: 140px;
  height: auto;
  .error_text {
    text-align: center;
  }
  .ap_view_profile_wrapper {
    min-height: 140px;
    // border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    .profile_inner_wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      min-height: 140px;
      width: 100%;
      padding: 16px 24px;
      padding-left: 40px;
      .profile_main_info_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        .profile_image_wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 21px 17px 21px 17px;
          height: 54px;
          width: 54px;
          border-radius: 50%;
          margin-right: 16px;
          @include breakpoint(lg) {
            height: 68px;
            width: 68px;
          }
          .profile_image {
            color: var(--primary-2-pure-white, #FFF);
            text-align: center;
            font-size: 18px;
            @include font_gt_walsheim_bold;
            line-height: 21px;
          }
        }
        .abo_info_name_number {
          width: calc(100% - 84px);
          margin-right: 24px;
          .abo_name {
            font-size: 24px;
            line-height: 28px;
            @include font_gt_walsheim_bold;
            @include ellipsis_two_liner;
            margin-bottom: 8px;
            @include breakpoint(lg) {
              font-size: 32px;
              line-height: 36px;
              margin-bottom: 4px;
              min-height: 40px;
            }
          }
          .ap_country {
            font-size: 14px;
            line-height: 18px;
            display: flex;
            @include font_gt_walsheim;
            @include breakpoint(lg) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        @include breakpoint(lg) {
          width: calc(90% - 24px);
          height: 100%;
          margin-bottom: 0;
        }
      }
      .ap_manager_details_wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
        @include breakpoint(lg) {
          width: 14%;
          padding-top: 16px;
        }
        .field_title_wrapper {
          display: flex;
          margin-bottom: 2px;
          @include font_gt_walsheim_medium;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.28px;
          @include breakpoint(lg) {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .field_data_wrapper {
          display: flex;
          @include font_gt_walsheim_bold;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.28px;
        }
      }
      @include breakpoint(lg) {
        width: calc(100% - 16px);
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 24px 0 24px 48px;
      }
    }
  }
}
