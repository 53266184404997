@import '../../../App.scss';

.checkbox_wrapper {
  .checkbox_inner_wrapper {
    display: flex;
    align-items: center;

    .checkbox_custom {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      cursor: pointer;

      &.checkbox_custom_disabled {
        cursor: auto;
      }
    }

    .checkbox_label {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;

      &.active_lbl {
        @include font_gt_walsheim_bold;
      }
    }

    .checkbox_label_report {
      font-size: 16px;
      line-height: 24px;
      padding-right: 5px;
      @include font_gt_walsheim_medium;

      &.active_lbl {
        @include font_gt_walsheim_bold;
      }
    }
  }

  &.tag_dropdown {
    .checkbox_inner_wrapper {
      justify-content: space-between;
      flex-direction: row-reverse;
    }
  }
}