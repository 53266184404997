@import "../../App.scss";

.ap_main_container {
  width: 100%;
  height: 100%;

  .ap_empty_state_container {
    padding: 32px 48px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include breakpoint(md){
      padding: 32px 24px;
    }
    .ap_dropdown_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .ap_dropdown_inner_wrapper {
        position: relative;
        width: 100%;
        .ap_dropdown {
          position: absolute;
          left: 0;
          width: 311px;
        }
      }
    }

    .no_ap_selected_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 80px;
      border-radius: 12px;
      padding: 48px;

      .no_ap_selected_image {
        width: 298px;
        height: 207px;
        flex-shrink: 0;
        object-fit: cover;
        margin-bottom: 24px;

      }

      .no_ap_selected_text {
        @include font_gt_walsheim;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}