@import "../../../styles/all.scss";

.GAP_wrapper {
  display: flex;       
  flex: 1;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  .GAP_inner_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;  
    width: 100%;   

    @include breakpoint(md){
      flex-direction: row;  
      gap: 48px;
    }
    .section_wrapper {
      flex: 1;                    
      margin-bottom: 45px;
      @include breakpoint(md){
        margin-bottom: 20px;
      }
    }
    .section_label {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 12px;
    }
    .section_divider {
      border: 0;
    }
  }

}
.viewmore_Section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding-top: 0;
  .viewmore_label {
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    @include font_gt_walsheim_bold;
    font-size: 16px;
    line-height: 24px;
  }
}
