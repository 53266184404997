@import "../../../../App.scss";

.violation_container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .ai_logo {
    margin-right: 16px;
  }

  .abo_name {
    font-size: 18px;
    line-height: 21px;
    @include font_gt_walsheim_bold;
    margin-bottom: 4px;
  }

  .abo_details {
    font-size: 14px;
    line-height: 18px;
    @include font_gt_walsheim_medium;
    margin-bottom: 12px;
  }

  .violation_status {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .violation_text {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_medium;
      margin-right: 8px;
    }

    .flag_wrapper {
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 4px;

      .flag {
        margin-right: 4px;
      }
    }
  }

  .violation_desc {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(md){
      font-size: 16px;
      line-height: 24px;
    }
    .kpi_details {
      display: flex;
      flex: 1 1 50%;
      max-width: 50%;
      &:nth-child(even){
        padding-left: 16px;
      }
      @include breakpoint(md){
        flex: 1 1 33.3%;
        max-width: 33.3%;
        &:nth-child(even){
          padding-left: 0;
        }
      }
      .kpi_wrapper {
        display: flex;
        white-space: nowrap;
        .value_wrapper {
          display: flex;
          align-items: center;
          padding-left: 4px;
          .value {
            @include font_gt_walsheim_bold;
          }
          .flag_wrapper {
            padding-left: 4px;
            background: none;
          }
        }

      }
    }
  }
  .flag_wrapper {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    .flag_type {
      font-size: 14px;
      line-height: 18px;
      @include font_gt_walsheim_medium;
    }

    .flag {
      margin-right: 4px;
    }
  }

  .linkText {
    font-size: 16px;
    line-height: 24px;
    @include font_gt_walsheim;
  }

  .linkLabel {
    font-size: 16px;
    line-height: 24px;
    padding-left: 20px;
    text-underline-offset: 4px;
    @include font_gt_walsheim_medium;
    display: flex;
    align-items: center;
  }

  .linkContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    .linkIcon {
      margin-top: 4px;
      margin-left: 5px;
      cursor: pointer;
      margin-bottom: 20px;
    }
  }
}