@import "../../../App.scss";

.text_area_wrapper {
  width: 100%;
  position: relative;
  @include font_gt_walsheim;

  .input_wrapper {
    position: relative;
    border-radius: 12px;

    .lbl_wrapper {
      padding-right: 10px;
      background-color: transparent;
      width: 100%;
      position: absolute;
      top: 2px;
      left: 2px;
      @include font_gt_walsheim_medium;

      .lbl {
        position: relative;
        font-size: 14px;
        line-height: 18px;
        z-index: 1;
        padding: 12px 12px 4px;
        width: 100%;
        border-radius: 12px;
        margin-right: 12px;
      }
    }

    .text_area {
      width: 100%;
      position: relative;
      padding: 32px 5px 12px 12px;
      display: flex;
      align-items: flex-start;
      font-size: 16px;
      line-height: 24px;
      gap: 4px;
      outline: none;
      border-radius: 12px;
      resize: none;
      height: 186px;
      border: 0;
      @include font_gt_walsheim;
    }
  }
}

.insertLinkModalContainer {
  position: fixed;
  top: 35%;
  left: 40%;
  background-color: aqua;
  padding: 20px;
  border: "1px solid #ccc";
  border-radius: 8px;
  z-index: 1000;
}