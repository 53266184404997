@import "../../../../App.scss";

.goals_wrapper {
  width: 100%;

  .year_dropdown {
    display: flex;
    margin-bottom: 10px;
    margin-left: 0;
  }

  .heading_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .heading {
      @include font_gt_walsheim_bold;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      width: 70%;
    }

    .right_section {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .clickable {
        cursor: pointer;
      }

      .non_clickable {
        pointer-events: none;
        opacity: 0.5;
      }

      .edit_section {
        display: flex;
        @include font_gt_walsheim_bold;
        // font-size: small;
        line-height: 20px;
        align-items: center;
        padding-left: 10px;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 3px;
        cursor: pointer;

        .edit_icon_light {
          background-image: url("../../../../assets/fonts/edit-icon-light.svg");
          height: 15px;
          width: 14px;
          margin-right: 7px;
        }

        .edit_icon_dark {
          background-image: url("../../../../assets/fonts/edit-icon-dark.svg");
          height: 15px;
          width: 14px;
          margin-right: 7px;
        }
      }
    }
  }

  .sub_heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .goals_section {
    margin-bottom: 24px;

    .goals {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      word-break: break-all;
    }
  }

  .btns_wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 290px;
    margin-left: auto;
  }

  @include breakpoint(lg) {
    width: 100%;

    &.full_width {
      width: 100%;
    }
  }
}