// Variable name for themes color
$theme_bg_color: #ffffff;
$text_color: #2c2c2c;
$text_positive: #107F47;
$header_bg_color: #e0dddd;
$dim_grey_color: #707070;
$them_bg_milk_color: #f0fae5;
$dark_grey_color: #5e5e5e;
$card_tag_primary_bg: #f1f2ff;
$card_tag_non_primary_bg: #f0f0f0;
$color_dark_purple: #38539a;
$card_tag_non_primary_color: #5e5e5e;
$card_section_lbl: #2c2c2c;
$abo_field_title_color: #5e5e5e;
$abo_field_data_color: #2c2c2c;
$header_bg_color: #e0dddd;
$text_dim_gray: #707070;
$border_dim_gray: #e4e4e4;
$toggle_color_bg: #fafafa;
$theme_purple: #5969d6;
$dim_blue: #f4f4f4;
// $up_arrow_url: url("../assets/images/up_arrow_light.svg");
$down_arrow_url: url("../assets/images/LightTheme/dropdown_down_arrow.svg");
$theme_bg_color: #ffffff;
$disabled_btn_color: #d9d9d9;
$green_light_bg: #007775;
$color_light_gray: #d6d6d6;
$color_red: #d91734;
$color_light_red: #fdf4f4;
$right_arrow: url("../assets/images/LightTheme/right_arrow.svg");
$dark_black: #000000;
$amwayLogoDesk: url("../assets/images/Amway_logo_l.svg");
$amwayLogoTab: url("../assets//images//LightTheme/amway_logo_A.svg");
$success_green: #107F47;

// Classes for themes
.layout_container {
  background-color: $theme_bg_color;

  // &.login_page{    
  //   background: #FAFAFA;
  // }
  .main_container {
    .main_wrapper {
      border: 1px solid var(--border-border-primary, #E4E4E4);
      background: var(--surface-surface-primary, #FFF);
    }

  }
}

a {
  color: $text_color;
}

// Shimmers Effect
@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.animate {
  animation: shimmer 2s infinite linear;
  background: var(--skeleton-loading, linear-gradient(90deg, #E4E4E4 0.19%, #FAFAFA 50.19%, #E4E4E4 100%));
  background-size: 200% 100%;
}

.year_dropdown_container {
  border: 1px solid #e4e4e4;
}

.dropdown_container {
  .dropdown_button {
    color: $dim_grey_color;
  }

  .up_arrow {
    background-image: $down_arrow_url;
    transform: rotate(180deg);
  }

  .down_arrow {
    background-image: $down_arrow_url;
  }

  .dropdown_menu {
    border: 2px solid none;
    background-color: $theme_bg_color;
    color: $text_color;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.26);

    .dropdown_item {
      &:hover {
        background-color: $dim_blue;
      }
    }

    .dropdown_selected_item {
      // background-color: $dim_blue;
      color: $theme_purple;
      @include font_gt_walsheim_bold;
    }

  }
}

.tag_dropdown_wrapper {
  .tag_input {
    // border: 2px solid $border_dim_gray;
    background-color: $theme_bg_color;
    color: $text_color;

    &:focus {
      border: 2px solid $theme_purple;
    }
  }

  .dropdown_menu {
    border: 2px solid $theme_purple;
    background-color: $theme_bg_color;
    color: $text_color;

    .add_tag_btn {
      background-color: $theme_bg_color;

      &:hover {
        background-color: $dim_blue;
      }
    }

    .dropdown_item {
      background-color: $theme_bg_color;
      color: $text_color;

      &.checked {
        background: $dim_blue;
      }

      &:hover {
        background-color: $dim_blue;
      }
    }
  }
}

.hatv_header_wrapper {
  border-bottom: 1px solid $border_dim_gray;
}

.theme_switch_container {
  background-color: $toggle_color_bg;
  border: 1px solid $border_dim_gray;

  .toggle_switch_btn {
    background-color: $theme_purple;
  }
}

// Header Theme
.header_container {
  background-color: $theme_bg_color;
  color: $text_dim_gray;

  .bg_gradient {
    background: linear-gradient(90deg, rgba(196, 190, 174, 0.25) 0.98%, rgba(219, 207, 248, 0.25) 20.24%, rgba(160, 144, 207, 0.25) 74.21%, rgba(201, 191, 207, 0.25) 90.55%, rgba(186, 167, 211, 0.25) 100%), var(--surface-surface-primary, #FFF);
  }

  .top_bar_wrapper {
    border-bottom: 1px solid $border_dim_gray;
  }

  .main_header_wrapper {
    border-bottom: 1px solid $border_dim_gray;

    .right_section_container {
      .logout_btn {
        // background-color: $theme_purple;

        .logout_txt {
          // color: $text_color;
        }
      }
    }

    .lang_switch_select {
      color: $text_color;
    }

    .amway_logo {
      background-image: $amwayLogoTab;

      @include breakpoint(lg) {
        background-image: $amwayLogoDesk;
      }
    }

  }

  .notification_count {
    background: $theme_purple;
    color: $theme_bg_color;
  }

  .label {
    color: $dim_grey_color;
  }
}

.search_bar_container {
  border: 1px solid var(--border-border-secondary, #E4E4E4);
  background: var(--surface-surface-primary, #FFF);

  .search_input {
    color: $dim_grey_color;

    &::placeholder {
      color: $text_dim_gray;
    }
  }
}

.footer_container {
  background-color: $theme_bg_color;
  border-top: 1px solid $border_dim_gray;

  .navlist {
    .label {
      color: $text_color;
    }
  }
}

// Subheader Profile theme
.profile_wrapper {
  color: $text_color;
  // background-color: $dim_blue;
  background: linear-gradient(90deg, rgba(196, 190, 174, 0.25) 0.98%, rgba(219, 207, 248, 0.25) 20.24%, rgba(160, 144, 207, 0.25) 74.21%, rgba(201, 191, 207, 0.25) 90.55%, rgba(186, 167, 211, 0.25) 100%), var(--surface-surface-primary, #FFF);
  border-bottom: 1px solid #E4E4E4;

  .profile_image_wrapper {
    color: #FFF;
    background-color: $theme_purple;
  }

  .abo_number {
    color: $dark_grey_color;
  }

  .country {
    color: $dark_grey_color;
  }

  .field_title_wrapper {
    color: $text_dim_gray;
  }

  .account_name_wrapper {
    .account_name {
      color: $text_dim_gray;
    }

    .account_name_value {
      color: $text_color;
    }
  }

  .field_data_wrapper {
    color: $text_color;
  }
}

.ap_view_profile_wrapper {
  color: $text_color;
  // background-color: $dim_blue;
  background: linear-gradient(90deg, rgba(196, 190, 174, 0.25) 0.98%, rgba(219, 207, 248, 0.25) 20.24%, rgba(160, 144, 207, 0.25) 74.21%, rgba(201, 191, 207, 0.25) 90.55%, rgba(186, 167, 211, 0.25) 100%), var(--surface-surface-primary, #FFF);


  .field_title_wrapper {
    color: $dark_grey_color;
  }

  .field_data_wrapper {
    color: $text_color;
  }
}

// Side Pannel
.sidebar_container {
  .section_title {
    color: $theme_purple;
  }

  .link_item {
    &:hover {
      background-color: $dim_blue;
    }

    &.disabled_tab {
      color: $dim_grey_color;
    }
  }

  .selected_link {
    background-color: $dim_blue;
    @include font_gt_walsheim_medium;
    border-left: 4px solid $theme_purple;
  }
}

.contact_info_wrapper {
  border: 1px solid var(--border-border-primary, $border_dim_gray);
}

.content_inner {
  .content_inner_label {
    color: $text_color;
  }

  .view_more_text {
    color: $text_color;
  }

  .separator_line {
    background-color: $border_dim_gray;
  }
}

.small_separator_line {
  background-color: $border_dim_gray;
}

.contact_card_container {
  .contact_card {
    background-color: $theme_bg_color;

    &.border_right {
      border-right: 1px solid $border_dim_gray;
    }

    // .card_profile {
    //   border-bottom: 1px solid #ddd;
    // }
  }
}

.contact_profile_info {
  .card_text {
    &.green {
      color: $green_light_bg;
    }

    &.blue {
      color: $theme_purple;
    }
  }
}

.card_info {
  .card_text_info_header {
    color: $dim_grey_color;
  }

  .card_text_info {
    color: $text_color;
  }
}

.card_tag_container_primary {
  background-color: $dim_blue;

  .card_tag {
    color: $theme_purple;
  }
}

.card_tag_container_non_primary {
  background-color: $dim_blue;

  .card_tag {
    color: $green_light_bg;
  }
}

.abo_bg_container {
  .grey_text {
    color: $dim_grey_color;
  }

  .have_succession {
    border: 1px solid rgb(228, 228, 228)
  }
  .details_wrapper {
    .flex_wrapper {
      &:first-child {
        @include breakpoint(md){
          border-right: 1px solid #E4E4E4;
        }
      }
    }
  }
}

.text_area_wrapper {
  .input_wrapper {
    border: 1px solid $border_dim_gray;

    .lbl {
      color: $dim_grey_color;
      background-color: $theme_bg_color;
    }

    .text_area {
      background-color: $theme_bg_color;
      color: $text_color;

      &:focus {
        border: 2px solid $theme_purple !important;
      }
    }
  }
}

.button {
  &.unfilled {
    background-color: $theme_bg_color;
    border: 2px solid $text_color;
    color: $text_color !important;
  }

  &.filled {
    background-color: $text_color;
    color: $theme_bg_color;
  }

  &.disabled {
    background-color: $disabled_btn_color;
    color: $dark_grey_color;
  }

  .spinner {
    border: 4px solid $color_light_gray;
    border-top: 4px solid $color_dark_purple;
  }
}

// common spinner
.spinner {
  border: 4px solid $color_light_gray;
  border-top: 4px solid $color_dark_purple;
}

.goals_wrapper {
  .sub_heading {
    color: $dim_grey_color;
  }

  .sub_heading_with_goals {
    color: $card_section_lbl;
  }

  .goals_section {
    .goals {
      color: $dim_grey_color;
    }
  }
}

.section_sub_heading {
  color: $dim_grey_color;
}

// Tables
.table_container {
  .tbl_header {
    .header_cell {
      background-color: $dim_blue;
      border-right: 1px solid $border_dim_gray;

      img {
        width: 16px;
        height: 16px;
        display: none;
      }
    }

    .header_cell:hover {
      background-color: #cacaca;
    }

    .header_cell:hover img {
      display: flex;
    }

    .header_cell_myaccount {
      background-color: #f4f4f4;
      border-right: 1px solid #e4e4e4;
    }

    .header_cell_myaccount img {
      display: flex;
    }
  }

  .tbl_data_row {
    .data_cell {
      border: 1px solid $border_dim_gray;

      .download_icon {
        border-right: 1px solid $border_dim_gray;
      }
    }
  }
}

.table_sub_title {
  color: $dim_grey_color;
}

.table_no_data {
  border: 1px solid $border_dim_gray;
  background: $dim_blue;
  color: $text_dim_gray;
}

// Modal Theme
.modal_container {
  .modal_backdrop {
    // background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(112, 112, 112, 0.7);
  }

  .modal_content {
    background-color: $theme_bg_color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

// Upload Component
.upload_container {
  .upload_wrapper {
    &:before {
      background: repeating-linear-gradient(to right,
          $border_dim_gray 0,
          $border_dim_gray 15px,
          transparent 15px,
          transparent 30px) 2px 0,
        repeating-linear-gradient(to bottom,
          $border_dim_gray 0,
          $border_dim_gray 15px,
          transparent 15px,
          transparent 30px) 0 2px,
        repeating-linear-gradient(to right,
          $border_dim_gray 0,
          $border_dim_gray 15px,
          transparent 15px,
          transparent 30px) 2px 100%,
        repeating-linear-gradient(to bottom,
          $border_dim_gray 0,
          $border_dim_gray 15px,
          transparent 15px,
          transparent 30px) 100% 2px;
      background-size: 100% 2px, 2px 100%;
      background-repeat: no-repeat;
    }

    .upload_content {
      // color: $dim_grey_color;
    }

    &.uploading_state {
      border: 2px dashed $theme_purple;
      background-color: $card_tag_primary_bg;
    }
  }
}

// Upload Document view modal
.uploaded_document_wrapper {
  .file_name {
    color: $theme_purple;
  }

  .file_details_wrapper {
    .file_size {
      color: $dim_grey_color;
    }

    .spinner {
      border: 2px solid $color_light_gray;
      border-top: 2px solid $color_dark_purple;
    }

    .uploading_text {
      color: $dim_grey_color;
    }
  }

  .error_container {
    color: $color_red;
    background-color: $color_light_red;
  }
}

// DropDown
.dropdown {
  .input_wrapper {
    &:focus {
      border: 2px solid #99B2F5;
    }
  }

  .dropdown_input {
    border: 2px solid $border_dim_gray;
    background-color: $theme_bg_color;
    color: $text_color;

    &.selected_input {
      border: 2px solid $theme_purple;
    }
  }

  .placeholder {
    color: $dim_grey_color;
  }

  .dropdown_menu {
    border: 2px solid $theme_purple;
    background-color: $theme_bg_color;

    .dropdown_item:hover {
      background-color: $dim_blue;
    }

    .dropdown_item.selected {
      // background-color: $dim_blue;
      color: $theme_purple;
    }
  }
}

.succession_plan_container {
  .label {
    border-bottom: 2px solid $text_color;
  }
}

.action_plan_document_container {
  .label {
    border-bottom: 2px solid $text_color;
  }
}

//css for the formatter options

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: #2C2C2C;
  ;
}

.ql-toolbar .ql-fill {
  fill: #2C2C2C;
  ;
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: #2C2C2C;
  ;
}

.ql-editor::before {
  color: $dim_grey_color;
}

.link_modal{
  background-color: #FFFFFF;
  border: "1px solid #E4E4E4";
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.26);
  .link_text{

  }
  .input_text_outer{
    border: 2px solid #E4E4E4;
    background-color: #FFFFFF;
    color: #2C2C2C;
  }

  .input_text{
    border: none;
    background-color: #FFFFFF;
    color: #2C2C2C;
  }
  input::placeholder,
  textarea::placeholder {
    color: #5E5E5E;
  }

  .button_style{
    cursor: pointer;
    border: 2px solid #2C2C2C;
    background-color: #FFFFFF;
    color: #2C2C2C;
  }
}

// Progress note
.notes_container {
  .input_text_area {
    background-color: $theme_bg_color;
    border: 1px solid $border_dim_gray;
    color: $dim_grey_color;

    &:focus {
      border: 2px solid $theme_purple;
    }
  }

  .progress_note {
    border-bottom: 1px solid $border_dim_gray;

    &:last-child {
      border-bottom: 0;
    }
  }

  .initials {
    color: $theme_purple;
    border: 1px solid $border_dim_gray;
    background: $dim_blue;
  }

  .last_modified {
    color: $dim_grey_color;
  }

  .note_content {
    color: $dim_grey_color;
  }
}

// Myaccount and leg analysis
.profile_table {
  .header_cell {
    &:first-child {
      background-color: $dim_blue;
    }
  }

  .data_cell {
    background-color: $theme_bg_color;

    .table_info {
      .labelInfo {
        @include underline-rounded($dark_black, 2px, 1px);
      }
    }

    &:first-child {

      border-right: 1px solid $border_dim_gray !important;
      z-index: 1;
    }

    &:nth-child(2) {
      border-left: 0;
    }

    &:last-child {
      border-right: 1px solid $border_dim_gray !important;
    }
  }
}

.data_cell {
  .info_logo_outer {
    background-color: $dim_blue;
  }

  .info_logo_inner {
    color: $theme_purple;
  }

  .table_info {
    .labelInfo {
      @include underline-rounded($dark_black, 2px, 1px);
    }
  }

  &:first-child {
    background-color: $theme_bg_color;
  }

  .data_item {
    border-right: 1px solid $border_dim_gray;

    .data_label {
      color: $dim_grey_color;
    }
  }

}

// search dropdown
.user_profile_dropdown {
  background: $theme_bg_color;
  box-shadow: var(--X, 0px) var(--Y, 2px) var(--Blur, 16px) var(--Spread, 6px) var(--shadow-drop-shadow, rgba(0, 0, 0, 0.10));

  .search_dropdown_title {
    background-color: $theme_bg_color;
  }

  .user_profile_wrapper {
    border-bottom: 1px solid $border_dim_gray;

    .user_name {
      @include underline-rounded($dark_black, 2px, 1px);
    }

    .user_info {
      color: $dim_grey_color;

      .abo_num {
        border-right: 2px solid $border_dim_gray;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  .no_result_text {
    color: var(--text-text-secondary, $dim_grey_color);
  }
}

// For common 
.profile_nav_btn {
  background-image: $right_arrow;
}

// Customize modal theme
.customize_view_modal {

  // background-color: ;
  .tabs_wrapper {
    background: var(--surface-surface-highlight, $dim_blue);

    .tablink {
      color: $text_dim_gray;

      &.active_tab {
        color: var(--text-text-brand, $theme_purple);
        border-bottom: 2px solid $theme_purple;
      }
    }
  }

  .step_title {
    color: $text_dim_gray;
  }

  .input_field {
    &.input_with_text {}
  }

  .placeholder {
    color: $text_dim_gray;
  }

  .bottom_button_wrapper {
    background: var(--surface-surface-highlight, $dim_blue);
    box-shadow: 0px -2px 6px 0px rgba(44, 44, 44, 0.1);
  }
}

// Checkbox 
.checkbox_wrapper {
  .checkbox_label {
    color: $text_color;
  }
}

// Saved view card
.saved_view_card {
  background: var(--surface-surface-footer, #f4f4f4);

  .tag {
    background: var(--surface-surface-deselected, #FAFAFA);
    color: $theme_purple;
  }
}

// Error in page
.error_in_page_wrapper {
  border: 1px solid var(--border-border-primary, $border_dim_gray);
  background: var(--surface-surface-tertiary, $theme_bg_color);
}

.myAccountContainer {
  .selected_view_btn {
    background-color: $theme_purple;
    color: $theme_bg_color;
    border: none;
  }

  .view_btn {
    border-color: #E4E4E4;
  }
}

// Comming Soon 
.coming_soon_section {
  border: 1px solid var(--border-border-primary, $border_dim_gray);
  background: var(--surface-surface-tertiary, $theme_bg_color);
}

// MYAccounts 
.section_title_head {
  color: $text_color;

  .btn_wrapper {
    .view_btn {
      border-color: $border_dim_gray;
    }
  }
}

.login_container {

  // background: #FAFAFA;
  .error_message {
    color: $color_red;
  }

  .login_header {
    // background: #FAFAFA;
    border-bottom: 1px solid #E4E4E4;
  }
}

.bottom_line {
  border-top: 1px solid $border_dim_gray;
}

.ap_view_profile_container {
  .ap_profile_image_wrapper {
    background-color: $theme_purple;
  }
}

// Tag Tab while selecting
.tag_container {
  background-color: $theme_purple;

  .tag_label {
    color: $theme_bg_color;
  }
}

.tag_unselected {
  border: 1px solid $border_dim_gray;
}

// selected tab in note
.tab_tag_container {
  .tab_label {
    background: $dim_blue;
    color: $theme_purple;
  }

}

// Filter head tags 
.filter_head_wrapper {
  .filter_tag {
    border: 1px solid $border_dim_gray;
    background-color: $theme_bg_color;
  }

  .active_filter_tag {
    color: $theme_bg_color;
    background-color: $theme_purple;
  }
}

.ap_main_container {
  .ap_empty_state_container {
    border: 1px solid $border_dim_gray;
    background: #FFF;

    .no_ap_selected_text {
      color: $dim_grey_color;
    }
  }
}

.business_performance {
  .insight_content_wrapper {
    background: linear-gradient(0deg, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 0%, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 100%), var(--surface-surface-primary, #FFF);
    color: $text_color;
  }

  .header {
    @include font_gt_walsheim_bold;

    .title {
      font-weight: bold;
      color: $text_color;
    }

    .playbook_link {
      color: $text_color;

      .playbook_text {
        &::after {
          background-color: $text_color;
        }
      }
    }
  }

  .last_closed_month_wrapper {
    .last_closed_month {
      color: $dim_grey_color;
    }
  }

  .insight_container {
    .ai_insight {
      color: #1176A9;
    }

    background-color: #e5f2f8;

    .update_section {
      .update_text {
        color: $dim_grey_color;
      }
    }
  }


}

.summary_section {
  .tile_container {
    border: 1px solid $border_dim_gray;

    .tile_main_wrapper {
      .tile_upper_section {
        .tile_header {
          color: $dim_grey_color;
        }

        .tile_body {
          .tile_change_value_wrapper {
            .change_value {
              &.positive {
                color: $text_positive;
              }

              &.negative {
                color: $color_red;
              }

              &.nill {
                color: $dim_grey_color;
              }
            }
          }
        }
      }

      .separator_line {
        border-top: 1px solid $border_dim_gray;
      }

      .tile_lower_section {
        color: $dim_grey_color;
      }
    }
  }

  .tile_value {
    color: $text_color;
  }
}


// AGC tile
.agc_details_tile {
  .agc_tile_wrapper {
    border: 1px solid var(--border-border-card, #E4E4E4);
    background: var(--surface-surface-tertiary, #FFF);

    .title {
      color: $card_section_lbl;
    }

    .desc {
      color: $dim_grey_color;
    }
  }
}

// BenchMark Ccmp
.benchmark_container {
  .label {
    color: #707070;
  }

  .marker_wrapper {
    color: #707070;
  }

  .progress_bar {
    background-color: #F4F4F4;
  }
}

// In AGC
.benchmark_value {
  background: #F4F4F4;

  .yoy_value {
    color: $card_section_lbl;
  }

  .yoy {
    color: $card_section_lbl;

    .rise {
      color: $success_green;
    }

    .fall {
      color: $color_red;
    }
  }
}

// AI insights
.ai_insight_wrapper {
  background: linear-gradient(0deg, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 0%, var(--surface-surface-ai-insight-blue, rgba(17, 118, 169, 0.15)) 100%), var(--primary-2-pure-white, #FFF);

  .ai_title {
    color: #1176A9;
  }

  .update_date {
    color: $dim_grey_color;
  }

  .ai_content {
    color: $card_section_lbl;
  }
}

// Graph Card
.graph_card_container {
  border: 1px solid var(--border-border-card, #E4E4E4);
  background: var(--surface-surface-tertiary, #FFF);

  .card_footer {
    .current_month_year {
      color: var(--text-text-secondary, #AEAEB2);
    }

    .data_value {
      color: #5969D6;
    }

    .value_info_wrapper {
      .value_diff {
        color: $dim_grey_color;
      }

      &.raised_change {
        .percentage_value_change {
          color: $success_green;
          background: #F0FAE5;
        }
      }

      &.fall_change {
        .percentage_value_change {
          color: $color_red;
          background: #FDF4F4;
        }
      }
    }
  }
}


// Common Benchmark component
.benchmark_wrapper {
  .benchmark_title_section {
    color: $dim_grey_color;
  }

  .benchmark {
    color: $dim_grey_color;

    .benchmark_track {
      background-color: $dim_blue;

      .benchmark_sections {
        border-right: 1px solid $dark_grey_color;
        border-left: 1px solid $dark_grey_color;

        .benchmark_section {
          border-right: 2px dashed $dark_grey_color;

          &:last-child {
            border-right: none;
          }

          &.middle {
            border-right: 2px solid $dark_grey_color;
          }
        }
      }
    }
  }
}

// Year table/chart
.annual_tbl_wrapper {
  border: 1px solid var(--border-border-secondary, #E4E4E4);

  .tbl_head {
    th {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  .tbl_row {
    &.even_row {
      background-color: #F4F4F4;
    }

    &.odd_row {
      background-color: $theme_bg_color;
    }

    .positive {
      color: $success_green;

    }

    .negative {
      color: $color_red;
    }
  }
}

.tooltip_wrapper {
  .tooltip {
    // color: #000000;
    background-color: $theme_purple;

    &::after {
      border-top: 6px solid $theme_purple;
    }
  }

  .tooltip_text {
    color: $theme_bg_color;
    // font-family: "GT Walsheim Pro", sans-serif;
  }

  .bold_text {
    color: $theme_bg_color;
  }
}

// 90 days Activation rate 
.activation_wrapper {
  background-color: $dim_blue;

  .rate_period {
    color: $theme_purple;

    &.selected_period {
      color: $theme_bg_color;
      background-color: $theme_purple;
    }
  }
}

// KPI coming soon baner
.kpi_coming_soon {
  border: 1px solid var(--border-border-secondary, #E4E4E4);

  .kpi_text {
    color: #AEAEB2;
  }
}

// AI Chat Bot 

// Profile
.profile_card {
  border: 1px solid #E4E4E4;

  &:hover {
    border: 0.5px solid #007775;
  }

  .flag_wrapper {
    background: var(--surface-surface-highlight, #F4F4F4);

    .red_flag {
      color: #D91734;
    }

    .yellow_flag {
      color: rgb(218 118 0)
    }
  }

  .violation_desc {
    color: var(--text-text-secondary, #AEAEB2);

    .bold_kpi_value {
      color: #2c2c2c;
    }
  }
}

// violation
.violation_container {
  .flag_wrapper {
    background: var(--surface-surface-highlight, #F4F4F4);

    .red_flag {
      color: #D91734;
    }

    .yellow_flag {
      color: rgb(218 118 0)
    }
  }

  .violation_desc {
    color: $text_color;
  }
}

.followup_container {
  background-color: $theme_bg_color;

  .question_text {
    color: #007775;
  }

  .followup_heading {}
}

.question_text {
  color: #007775;
}

.followup_item {
  color: #707070;
  border-top: 1px solid #E4E4E4;

  &:hover {
    .followup_text {
      color: #2C2C2C;
    }
  }

  .followup_arrow {
    background-color: #FFF;
  }
}

// Markdown theme changes
.markdown_styled {
  color: $text_color;

  a {
    color: #1176A9;
    border-bottom: 2px solid #1176A9;
  }
}

.abo_list_container {
  .inner_wrappwer {
    background-color: #ffffff;
    border: 1px solid #E4E4E4;

    .try_again {
      color: #2c2c2c;
    }
  }
}

.report_wrapper {
  .report_heading {
    color: #2C2C2C;
  }

  .report_subheading {
    color: #707070;
  }

  .card {
    background-color: #F1F2FF;

    &:hover {
      border: 1px solid var(--color-dark-mode-cold-purple-accessible, #99B2F5);
    }

    .card_heading {
      color: #5969D6;
    }

    .card_content {
      color: #707070;
    }
  }
}

.drawer_container {
  background-color: red($color: #000000);

  .drawer_backdrop {
    background-color: rgba(112, 112, 112, 0.7);
  }

  .drawer {
    background-color: #FFFFFF;

    .drawer_content {
      color: #2C2C2C;
    }
  }
}

.hatvSubheaderContainer {
  background-color: #FFFFFF;
  border-bottom: 1px solid #e4e4e4;
}

//report card
.card_wrapper {
  .create_report_button {
    background-color: #fff;

    border: 2px solid var(--border-border-tertiary, #E4E4E4);

    .create_text {
      color: #2C2C2C;

    }
  }

  .report_card {
    border: 1px solid #E4E4E4;
    background: #FFF;
    color: #fff;

    .report_header {
      border-bottom: 1px solid #E4E4E4;

      .report_title_wrapper {
        color: #2C2C2C;

      }

      .first_supporting_text {
        color: #2C2C2C;
      }

      .second_supporting_text {
        color: #707070;
      }

      .preview_button {
        color: #2C2C2C;
      }

      .download_button {
        background-color: #FFF;
        border-color: #E4E4E4;
      }
    }

    .report_insight {
      background: #ffffff;
    }
  }
}

//abo empty state
.abo_empty_state_container {
  background-color: #FFF;
  border-radius: 12px;
  border: 1px solid #E4E4E4;
  background: #FFF;

  .below_silver_text {
    color: #2C2C2C;
  }
}

.dots_anime {
  color: #707070;
}

.rightArrowIcon {
  background-color: #F4F4F4;
}

// Main Sidebar
.main_sidebar {
  .bg_gradient {
    background: linear-gradient(180deg,
        rgba(196, 190, 174, 0.25) 0%,
        rgba(219, 207, 248, 0.25) 19.45%,
        rgba(160, 144, 207, 0.25) 67.45%,
        rgba(196, 190, 174, 0.25) 90.45%,
        rgba(186, 167, 211, 0.25) 100%);
  }

  .side_nav_item {
    color: $text_dim_gray;
  }

  .nav_item {
    &:hover {
      background-color: $theme_bg_color;
    }

    &.selected_nav_item {
      background-color: $theme_bg_color;

      .side_nav_item {
        color: #5969D6;
      }
    }

  }
}

.profile_container {
  .flex_wrapper {
    .tab_wrapper {
      border-bottom: 1px solid $border_dim_gray;

      .tab_name {
        &.selected {
          border-bottom: 2px solid $text_color;
        }
      }
    }
  }
}

.GAP_wrapper {
  .section_divider {
    @include breakpoint(md) {
      border: 1px solid #E4E4E4;
    }
  }
}

.login_header {
  border-bottom: 1px solid #E4E4E4;
}

//award qulification journey
.qualificaiton_info_wrapper {
  .sub_label {
    color: #707070;
  }

  .qualification_main_wrapper {
    border-bottom: 1px solid #E4E4E4;

    .award_tbl_wrapper {
      .tbl_head {
        background-color: #E4E4E4;

        th {
          border-right: 1px solid #FFF;
          color: #2C2C2C;
        }
      }

      .tbl_row {
        &.even_row {
          background-color: #F4F4F4;
        }

        &.odd_row {
          background-color: #E4E4E4;
        }

        td {
          border-right: 1px solid #FFF;
          color: #2C2C2C;
        }
      }

      .tracking_tag {
        color: #5969D6;
        background-color: #FFF;
      }
    }
  }
}