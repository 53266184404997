@import "../../../../App.scss";

.succession_plan_container {
  position: relative;
  width: 100%;
  // padding-bottom: 220px;
  @include font_gt_walsheim;

  .title {
    font-size: 24px;
    line-height: 28px;
    @include font_gt_walsheim_bold;
    margin-bottom: 4px;
  }

  .description_txt {
    color: #707070;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  .upload_btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .sub_title {
      font-size: 16px;
      line-height: 24px;
      @include font_gt_walsheim_bold;
    }

    .icon_wrapper {
      @include flex_center;
      cursor: pointer;

      .upload_icon {
        width: 16px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 8px;
      }

      .label {
        font-size: 16px;
        line-height: 24px;
        @include font_gt_walsheim_bold;
      }
    }
  }

  @include breakpoint(sm) {
    width: 75%;
    padding: 0;

    &.full_width {
      width: 100%;
    }
  }
}