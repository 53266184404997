.radio_option {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    font-size: 16px;
  
    .custom_radio {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  